import * as gtm from './gtm';
import * as matomo from '@elements/tracking/src/matomo';
import {on, closest} from "@elements/dom-utils";
import {getPrefixedDataSet} from "@elements/data-set-utils";
import {onFind} from "@elements/init-modules-in-scope";

export const initGtmEventTracking = createEventTracking(gtm);
export const initMatomoEventTracking = createEventTracking(matomo);

function createEventTracking({setup, trackEvent}) {
    return ({dataAttributePrefix = 'event-tracking', debug = false} = {}) => {
        setup({debug});

        // via object key in _trackingData config variable
        onFind(`[data-${dataAttributePrefix}-key]:not(.js-go-to-link), [data-${dataAttributePrefix}-key].js-go-to-link a`, function(element) {

            // needed because of js-go-to-link possibility
            let trackingElement = element.getAttribute(`data-${dataAttributePrefix}-key`) ? element : closest(`[data-${dataAttributePrefix}-key]`,element);

            let trackingKey = trackingElement.getAttribute(`data-${dataAttributePrefix}-key`),
                trackingObject = _trackingData[trackingKey];

            if (trackingObject) {
                let trigger = trackingObject.trigger || 'click';
                if (trigger === 'ajax-form'){
                    on('fetched.ajax-form', function(evt) {
                        let result = evt.detail;
                        let element = evt.target;

                        if (trackingObject.responseTracking === true){
                            if (result){
                                resultTracking(result, debug);
                            }else{
                                console.warn('No Result');
                            }
                        }else{
                            let clickedTrackingKey = element.getAttribute(`data-${dataAttributePrefix}-key`);

                            if (_trackingData[clickedTrackingKey]) {
                                trackEvent(_trackingData[clickedTrackingKey], {debug});
                            }
                        }
                    }, trackingElement);
                }else{
                    on(trigger, function(evt) {
                        let triggeredElement = evt.currentTarget;

                        // needed because of js-go-to-link possibility
                        let trackingElement = triggeredElement.getAttribute(`data-${dataAttributePrefix}-key`) ? triggeredElement : closest(`[data-${dataAttributePrefix}-key]`,triggeredElement);

                        let clickedTrackingKey = trackingElement.getAttribute(`data-${dataAttributePrefix}-key`);

                        //trackevent with key
                        if (_trackingData[clickedTrackingKey]) {
                            trackEvent(_trackingData[clickedTrackingKey], {debug});
                        }

                    }, trackingElement);
                }
            }
        });

        // For generic event set in Pimcore
        onFind(`[data-${dataAttributePrefix}-event]`, function(trackingElement) {
            on('click', function(evt) {
                let clickedElement = evt.currentTarget;
                let data = {
                    datalayer : getPrefixedDataSet(`${dataAttributePrefix}`, clickedElement)
                };

                trackEvent(data, {debug});
            }, trackingElement);
        });
    }
}

function trim(str) {
    return str.replace(/\s\s+/g, ' ').trim();
}

export function responseTracking(request, debug = false) {
    // Unpack json response body if the promise was created via fetch
    let promise = request.then(response => (response
        && response.json
        && typeof response.json === 'function'
        && response.clone
        && typeof response.clone === 'function')
        ? response.clone().json()
        : response
    );

    promise.then(result => {
        let responseTrackingData = result.responseTrackingData;
        if (responseTrackingData) {
            try {
                execResponseTracking(responseTrackingData,true);
            } catch (e) {
                console.error(e)
            }
        }
    });
}

export function resultTracking(result, debug = false) {
    let responseTrackingData = result.responseTrackingData;
    if (responseTrackingData) {
        try {
            execResponseTracking(responseTrackingData, debug);
        } catch (e) {
            console.error(e)
        }
    }
}

function execResponseTracking(responseTrackingData,debug){

    Object.keys(responseTrackingData).forEach(service => {
        console.log(service, responseTrackingData[service]);

        if (service === 'gtm'){
            window.dataLayer.push(responseTrackingData[service].datalayer);

            if (debug){
                console.log('GTM Tracking - dataLayer push:', responseTrackingData[service].datalayer);
            }

        }else if(service === 'matomo'){
            _paq.push((responseTrackingData[service].datalayer))

            if (debug){
                console.log('Matomo event tracking - _paq.push:', responseTrackingData[service].datalayer);
            }
        }
    });
}
