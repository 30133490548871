export function setup() {
    window.dataLayer = window['dataLayer'] || [];
}

export function trackEvent(data, {debug}) {
    // create copy of data object so gtm.uniqueEventId is not set in the original object
    const eventData = {
        ...data.datalayer,
        event: data.datalayer.event
    };
    window.dataLayer.push(eventData);

    if (debug) {
        console.log('GTM Tracking - dataLayer push:', eventData);
    }
}

function exists(x) {
    return x !== null && typeof x !== 'undefined'
}